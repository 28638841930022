.work-container {
    background-color: #a0b3c1;
    text-align: center;
    padding-bottom: 3rem;
}
.work-title {
    font-size: 40px;
    color: #2f5d8c;
    letter-spacing: 0.5rem;
    line-height: 4rem;
}
.work-subtitle {
    font-size: 20px;
    color: #2f5d8c;
    letter-spacing: 0.18rem;
    margin-bottom: 3rem;
}

.CardContainer {
    width: 80vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: wrap;
    row-gap: 30px;
}
.row {
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
}

/* -------------------------Media Query Iphone ---------------------- */

@media (max-width: 480px) {
    .work-container {
        padding-left: 1.5rem;
    }
    .work-title {
        font-size: 20px;
        line-height: 5rem;
        margin-bottom: -1rem;
    }
    .work-subtitle {
        font-size: 12px;
        letter-spacing: 0.08rem;
        margin-bottom: 1rem;
    }
}
