.contact {
    height: 100vh;
    background-color: rgb(70, 93, 239);
}

.contact-title {
    margin-top: 7rem;
    margin-bottom: 2rem;
    color: #1c1c1c;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.label {
    color: aliceblue;
}
.name,
.email,
.textarea,
.submit-btn {
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    width: 35%;
    color: #000;
}

.textarea {
    height: 100px;
}
.name:focus,
.email:focus,
.textarea:focus {
    color: rgb(68, 0, 255);
    outline-color: #1d74de;
    background-color: #fafafa;
}
.submit-btn {
    border: 0 solid rgb(165, 222, 242);
    background-color: rgb(165, 222, 242);
    cursor: pointer;
}

.logo-container {
    margin: auto;
    width: 20vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 2rem;
}
.logo3 {
    width: 60px;
}

/* -----------Media Query Iphone --------- */
@media (max-width: 480px) {
    .contact-title {
        margin-top: 2rem;
        margin-bottom: 3rem;
        font-size: 20px;
    }

    .label {
        font-size: 12px;
    }
    .name,
    .email,
    .textarea,
    .submit-btn {
        margin-bottom: 1.5rem;
        padding: 0.5rem;
        width: 55%;
        margin-left: 1rem;
        font-size: 12px;
    }
    .logo-container {
        width: 30%;
        padding-top: 1.5rem;
    }
    .logo3 {
        width: 30px;
    }
}
