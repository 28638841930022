.footer {
    width: 100%;
    background-color: #d8d8d6;
    /* display: block; */
    text-align: center;
    color: rgb(6, 67, 122);
}

/* -------------------------Media Query Iphone ---------------------- */

@media (max-width: 480px) {
    .footer {
        height: 1.5rem;
    }
    .copyright {
        font-size: 12px;
        color: rgb(6, 67, 122);
    }
}
