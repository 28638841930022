.menuIcon {
    background: transparent;
    border: "none";
    position: fixed;
    -webkit-overflow-scrolling: touch;
    width: 3rem;
    height: 1.6rem;
    margin-top: 2rem;
    margin-left: 2rem;
    cursor: pointer;
}
.nav {
    position: fixed;
    top: 5rem;
    left: 1rem;
    height: 100vh;
    text-align: center;
}

.navlink {
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.nav a:link,
.nav a:active,
.nav a:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
}
.nav a:hover {
    text-decoration: none;
    transition: 1s ease-in-out;
    font-size: 20px;
}

.nav ul {
    list-style-type: none;
    line-height: 3em;
}

.nav li {
    writing-mode: vertical-lr;
    padding: 1rem;
    letter-spacing: 0.2rem;
}

/* -----------Media Query Iphone --------- */
@media (max-width: 480px) {
    .menuIcon {
        width: 2rem;
        height: 1.6rem;
        margin-top: 2rem;
        margin-left: 2rem;
    }
    .nav {
        font-size: 12px;
        top: 3.3rem;
        left: 1.3rem;
    }
    .nav a:hover {
        font-size: 10px;
    }
    .nav ul {
        line-height: 1.5em;
    }
}
