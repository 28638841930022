.home {
    background-image: linear-gradient(
        310deg,
        hsl(231deg 64% 40%) 0%,
        hsl(231deg 62% 45%) 20%,
        hsl(231deg 61% 50%) 29%,
        hsl(230deg 71% 54%) 36%,
        hsl(230deg 83% 58%) 43%,
        hsl(230deg 96% 62%) 50%,
        hsl(230deg 83% 58%) 57%,
        hsl(230deg 71% 54%) 64%,
        hsl(231deg 61% 50%) 71%,
        hsl(231deg 62% 45%) 80%,
        hsl(231deg 64% 40%) 100%
    );

    /* min-height: 100vh; */
}
.logo_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
    padding-top: 5rem;
}
.logo-reza {
    width: 20rem;
    height: 20rem;
}
.hajipour {
    color: rgb(255 255 255);
    font-family: "Tangerine", cursive;
    font-size: 4rem;
    letter-spacing: 0.1rem;
    margin-top: -2rem;
}
.Fullstack {
    color: rgb(255 255 255);
    font-weight: 300;
    line-height: 2rem;
    margin-top: 1rem;
    letter-spacing: 0.2rem;
}

.btn_container {
    min-width: 25%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.work_btn {
    margin-left: 1rem;
}

.work_btn,
.about_btn {
    color: aliceblue;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border: none;
    border-radius: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer;
    font-family: "Red Hat Display", sans-serif;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    border: 0 solid #e5e7eb;
    border-width: 2px;
    border-color: rgb(165, 222, 242);
    background-color: rgba(40, 176, 176, 0.014);
}

.work_btn:hover,
.about_btn:hover {
    background-color: rgb(165, 222, 242);
    color: rgb(17, 23, 27);
}

/* -----------Media Query Iphone --------- */
@media (max-width: 480px) {
    .logo-reza {
        width: 12rem;
        height: 12rem;
        margin-top: 1rem;
    }
    .hajipour {
        font-size: 3rem;
        margin-top: 0;
    }
    .Fullstack {
        font-size: 1rem;
        line-height: 1rem;
        margin-top: 2rem;
    }
    .btn_container {
        min-width: 40%;
        margin-top: 3rem;
    }
    .work_btn,
    .about_btn {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.9rem;
        letter-spacing: 0;
        border-width: 1px;
    }
    .work_btn {
        margin-left: 1rem;
    }
}
