.about-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #d8d8d6;
    height: 100vh;
}

.aboutTitle1 {
    /* text-align: left; */
    font-size: 40px;
    letter-spacing: 0.5rem;
    line-height: 5rem;
    color: #2f5d8c;
    margin-bottom: 2rem;
}
.aboutTitle2 {
    font-size: 22px;
    color: #2f5d8c;
    letter-spacing: 0.3rem;
}
.aboutTitle3 {
    font-size: 18px;
    color: #2f5d8c;
}

.content-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 96vw;
}

.left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40vw;
    height: auto;
    margin-left: 4rem;
    padding: 1rem;
    background-color: #a0b3c1;
    color: rgb(16, 20, 21);
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.reza {
    width: 40%;
    /* border-radius: 9999px; */
    border: 1px solid #2f5d8c;
}
.aboutTitle2 {
    margin-top: -1rem;
}

.skills {
    font-size: 18px;
    color: #2f5d8c;
    /* line-height: 1.7rem; */
}

.codingSkills,
.otherSkills,
.languages {
    font-size: 16px;
    line-height: 1.5rem;
    text-align: justify;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
}

.right {
    width: 45vw;

    font-size: 19px;
    line-height: 2rem;
    text-align: justify;
    color: rgb(16, 20, 21);
}

/* -------------------------Media Query Iphone ---------------------- */

@media (max-width: 480px) {
    .about-container {
        height: 100%;
        /* padding-left: -1.5rem; */
    }
    .aboutTitle1 {
        font-size: 20px;
        line-height: 5rem;
        margin-bottom: -1.5rem;
    }

    .aboutTitle2 {
        font-size: 12px;
        letter-spacing: 0.08rem;
    }
    .aboutTitle3 {
        font-size: 12px;
        letter-spacing: 0.08rem;
    }
    .content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90%;
        height: 100%;
        font-size: 10px;
    }
    .left {
        width: 75vw;
        padding: 0.5rem;
        font-size: 10px;
        height: auto;
        margin-top: 1rem;
        margin-left: 2.5rem;
    }
    .reza {
        width: 25%;
        border: 2px solid #2f5d8c;
        margin-top: 0.5rem;
    }
    .content {
        font-size: 12px;
        line-height: 1rem;
    }
    .skill {
        font-size: 12px;
        line-height: 1.5rem;
    }

    .right {
        width: 75vw;
        font-size: 12px;
        line-height: 1rem;
        margin-top: 1rem;
        margin-left: 2.5rem;
    }
}
