.card {
    min-width: 335px;
    max-width: 335px;
    height: 380px;
    overflow: hidden;
    padding: 0;
    background-color: #f2f3f4;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: scale(1.05);
    transition: 1s ease-in-out;
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
}

.card-image img {
    margin: 0;
    width: 335px;
    height: 180px;
    object-fit: cover;
    display: block;
}

.card-body {
    padding: 5px 12px 15px;
    text-align: left;
    min-height: 120px;
    max-height: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.card-title {
    color: #2f5d8c;
    font-size: 16px;
    margin-bottom: 0.4rem;
    text-transform: uppercase;
}

.card-description {
    font-size: 14px;
}

.card-stack {
    /* text-transform: uppercase; */
    font-size: 14px;
    color: #2f5d8c;
    margin: auto;
    align-items: flex-end;
}
.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cardIcon {
    width: 30px;
}

.cardBtn {
    min-width: 120px;
    background: rgb(165, 222, 242);
    text-decoration: none;
    margin-top: 30px;
    padding: 4px 5px;
    border-radius: 7px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cardBtn:hover {
    transform: scale(1.05);
    transition: 1s ease-in-out;
    background: rgb(135, 209, 236);
}

.cardBtn:link,
.cardBtn:active,
.cardBtn:visited {
    text-decoration: none;
    color: #1c1c1c;
}

/* Responsive media queries */
@media screen and (max-width: 1000px) {
    .card {
        width: 40%;
    }
}

@media screen and (max-width: 620px) {
    .container {
        width: 100%;
    }

    .heading {
        padding: 20px;
        font-size: 30px;
    }

    .card {
        width: 80%;
    }
}

/* -----------Media Query Iphone --------- */
@media (max-width: 480px) {
    .card {
        min-width: 235px;
        max-width: 235px;
        height: 280px;
    }
    .card-image img {
        width: 235px;
        height: 110px;
    }
    .card-body {
        padding: 5px 12px 15px;
        min-height: 95px;
        max-height: 95px;
    }
    .card-title {
        font-size: 12px;
        margin-bottom: 0.2rem;
    }
    .card-description {
        font-size: 12px;
    }
    .card-stack {
        font-size: 12px;
    }
    .cardIcon {
        width: 18px;
    }
    .cardBtn {
        min-width: 95px;
        max-width: 95px;

        text-decoration: none;
        margin-top: 30px;
        padding: 4px 5px;
        border-radius: 7px;
    }
}
